<template>
    <div>
        <HeaderCard :loading="loading" tittle="Prestadores que fazem ASO retido" :buttonIcon="true" @onLoad="load" />
        <div class="flex flex-column">
            <p class="text-7xl font-normal m-0 mt-4 justify-content-center"> {{ dados[0]?.quantidade?.toLocaleString('pt-BR') ?? '0' }} </p>
        </div>
    </div>
</template>
<script>
import { getClientBase } from '@/services/http';
import HeaderCard from '../../components/HeaderCard.vue';

export default {
    props: {
        prestadorIds: {
            type: Array
        },
        grupoIds: {
            type: Array
        },
    },
    data() {
        return {
            dados: [],
            periodicidade: 'month',
            dataInicio: null,
            dataFim: null,
            quantidade: 12,
            loading: false,
        };
    },
    components: {
        HeaderCard
    },

    methods: {
        async load(params) {
            this.periodicidade = params.periodicidade;
            this.quantidade = params.quantidade;
            this.dataInicio = params.dataInicio;
            this.dataFim = params.dataFim;
            await this.queryData();
        },
        async queryData() {
            this.loading = true;
            try {
                const { data } = await getClientBase().get('/indicadores/prestadores/quantidade-utilizados/aso-retido', {
                    params: {
                    filtros: JSON.stringify({
                        periodicidade: this.periodicidade,
                        quantidade: this.quantidade,
                        prestadorIds: this.prestadorIds?.length ? [this.prestadorIds] : null,
                        dataInicio: this.dataInicio,
                        dataFim: this.dataFim,
                        })
                    }
                })
                this.dados = data;
                this.loading = false;
            } catch (error) {
                this.$toast.add({ severity: 'info', summary: 'Ops!', detail: 'Tivemos algum problema ao carregar os gráficos, por favor clique em recarregar!', life: 3000 });
                this.loading = false;
            }
        },
    },
}
</script>