<template>
    <div>
        <HeaderCard :filtroDefault="false" :loading="loading" buttonIcon filtroCidades tittle="Cidades sem prestador" @onLoad="load" />
        <DataTable
            :pt="{
                wrapper: { style: { height: 'calc(100% - 40px)' } }
            }"
            :rows="10"
            :totalRecords="total"
            :value="dados"
            class="h-13rem mt-4"
            currentPageReportTemplate="Página {currentPage} de {totalPages}"
            dataKey="id"
            lazy
            paginator
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
            scroll-height="100%"
            scrollable
            @page="onPage"
            @sort="onSort"
        >
            <template #empty> Nenhum registro encontrado. </template>
            <Column field="cidade" header="Cidades" sortable style="width: 25%"></Column>
            <Column field="estado" header="Estado" sortable style="width: 5%"></Column>
            <ProgressBar v-if="loading" class="w-full border-noround z-2 absolute" mode="indeterminate" style="height: 4px; top: 40px" />
        </DataTable>
    </div>
</template>
<script>
import { getClientBase } from '@/services/http';
import HeaderCard from '../../components/HeaderCard.vue';

export default {
    data() {
        return {
            dados: [],
            page: 1,
            total: 0,
            perPage: 10,
            cidadeIds: [],
            sortField: null,
            sortOrder: null,
            loading: false
        };
    },
    components: {
        HeaderCard
    },

    methods: {
        async load(params) {
            this.cidadeIds = params.cidadeIds?.map((p) => p.id) ?? [];
            await this.queryData();
        },
        async queryData() {
            this.loading = true;
            try {
                const { data } = await getClientBase().get('/indicadores/prestadores/cidades-sem-prestador', {
                    params: {
                        filtros: JSON.stringify({
                            cidadeIds: this.cidadeIds?.length ? this.cidadeIds : undefined
                        }),
                        paginacao: JSON.stringify({
                            limit: this.perPage,
                            page: this.page,
                            sortField: this.sortField,
                            sortOrder: this.sortOrder
                        })
                    }
                });
                this.dados = data.items;
                this.total = data.meta?.totalItems;
                this.totalPage = data.meta?.totalPages;
            } catch (error) {
                this.$toast.add({
                    severity: 'info',
                    summary: 'Ops!',
                    detail: 'Tivemos algum problema ao carregar os gráficos, por favor clique em recarregar!',
                    life: 3000
                });
            }
            this.loading = false;
        },
        onPage(event) {
            this.page = event.page + 1;
            this.perPage = event.rows;
            this.queryData();
        },
        onSort(event) {
            this.sortField = event.sortField;
            this.sortOrder = event.sortOrder;
            this.queryData();
        }
    }
};
</script>
<style scoped>
:deep(::-webkit-scrollbar-track) {
    background-color: #f4f4f436;
    border-radius: 15px;
    margin-left: 5px;
}

:deep(::-webkit-scrollbar) {
    width: 6px;
    height: 6px;
    background: #f4f4f4;
    margin-left: 5px;
}

:deep(::-webkit-scrollbar-thumb) {
    background: #ced4da;
    border-radius: 15px;
    margin-left: 5px;
}
</style>
