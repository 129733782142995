<template>
    <div class="card p-5">
        <HeaderPanel :tittle="'Prestador utilizado'" :loading="loading" nomeTelaDoManual="relatorios-e-indicadores-prestadores"/>
        <div class="grid" :class="{ 'disabled': loading }">
            <div class="lg:col-8 col-12">
                <label for="txtFilterCliente" class="mb-1">Prestadores</label>
                <MultiSelectPrestador v-model="suppliersSelectedId" dataKey="id" class="w-full"
                    placeholder="Selecione os prestadores..." />
            </div>
            <div class="lg:col-4 col-12">
                <div class="flex justify-content-end flex-row h-full">
                    <div class="flex align-items-end">
                        <Button type="button" label="Recarregar" class="h-3rem" @click="loadAllComponentes"
                            icon="pi pi-sync" loadingIcon="pi pi-sync pi-spin" :loading="loading" />
                    </div>
                </div>
            </div>
        </div>
        <div class="layout-content">
            <div class="grid h-auto">
                <div
                    class="col-12 sm:col-12 lg:col-4 md:col-12 p-0 sm:pt-2 lg:py-1 md:py-3 lg:pl-0 lg:pr-3 md:pl-0 md:pr-0 py-3">
                    <div class="p-5 border-round-md border-1 border-300 border lg:overflow-y-hidden lg:overflow-x-hidden md:overflow-y-hidden md:overflow-x-auto sm:overflow-y-hidden sm:overflow-x-auto overflow-y-hidden overflow-x-auto"
                        style="height: 30rem">
                        <TipoDeAtendimentoChart ref="tipoDeAtendimentoChart" :prestadorIds="prestadorIds" />
                    </div>
                </div>
                <div
                    class="col-12 sm:col-12 lg:col-8 md:col-12 p-0 sm:pt-2 lg:py-1 md:py-3 lg:pl-0 md:pl-0 lg:pr-0 md:pr-0 py-3">
                    <div class="p-5 border-round-md border-1 border-300 border" style="height: 30rem">
                        <UtilizacaoDoPrestadorChart ref="utilizacaoDoPrestadorChart" :prestadorIds="prestadorIds" />
                    </div>
                </div>
                <div
                    class="col-12 sm:col-12 lg:col-8 md:col-12 p-0 sm:pt-2 lg:py-1 md:py-3 lg:pl-0 md:pl-0 lg:pr-0 md:pr-0 py-3">
                    <div class="p-5 border-round-md border-1 border-300 border" style="height: 30rem">
                        <DisponibilidadeDeHorarioChart ref="disponibilidadeDeHorarioChart" :prestadorIds="prestadorIds" />
                    </div>
                </div>
                <div
                    class="col-12 sm:col-12 lg:col-4 md:col-12 p-0 sm:pt-2 lg:py-1 md:py-3 lg:pl-3 lg:pr-0 md:pl-0 md:pr-0 py-3">
                    <div class="p-5 border-round-md border-1 border-300 border lg:overflow-y-hidden lg:overflow-x-hidden md:overflow-y-hidden md:overflow-x-auto sm:overflow-y-hidden sm:overflow-x-auto overflow-y-hidden overflow-x-auto"
                        style="height: 30rem">
                        <QuantidadePorTipoExameChart ref="quantidadePorTipoExameChart" :prestadorIds="prestadorIds" />
                    </div>
                </div>
                <div
                    class="col-12 sm:col-12 lg:col-4 md:col-12 p-0 sm:pt-2 lg:py-3 md:py-3 lg:pl-0 lg:pr-0 md:pl-0 md:pr-0 py-3">
                    <div class="p-5 border-round-md border-1 border-300 border lg:overflow-y-hidden lg:overflow-x-hidden md:overflow-y-hidden md:overflow-x-auto sm:overflow-y-hidden sm:overflow-x-auto overflow-y-hidden overflow-x-auto"
                        style="height: 20rem">
                        <QuantidadeTotalPanel ref="quantidadeTotalPanel" :prestadorIds="prestadorIds" />
                    </div>
                </div>
                <div
                    class="col-12 sm:col-12 lg:col-4 md:col-12 p-0 sm:pt-2 lg:py-3 md:py-3 lg:pl-3 lg:pr-0 md:pl-0 md:pr-0 py-3">
                    <div class="p-5 border-round-md border-1 border-300 border lg:overflow-y-hidden lg:overflow-x-hidden md:overflow-y-hidden md:overflow-x-auto sm:overflow-y-hidden sm:overflow-x-auto overflow-y-hidden overflow-x-auto"
                        style="height: 20rem">
                        <QuantidadeAsoRetidoPanel ref="quantidadeAsoRetidoPanel" :prestadorIds="prestadorIds" />
                    </div>
                </div>
                <div
                    class="col-12 sm:col-12 lg:col-4 md:col-12 p-0 sm:pt-2 lg:py-3 md:py-3 lg:pl-3 lg:pr-0 md:pl-0 md:pr-0 py-3">
                    <div class="p-5 border-round-md border-1 border-300 border lg:overflow-y-hidden lg:overflow-x-hidden md:overflow-y-hidden md:overflow-x-auto sm:overflow-y-hidden sm:overflow-x-auto overflow-y-hidden overflow-x-auto"
                        style="height: 20rem">
                        <CidadesSemPrestadoresGrid ref="cidadesSemPrestadoresGrid" />
                    </div>
                </div>
                <div
                    class="col-12 sm:col-12 lg:col-4 md:col-12 p-0 sm:pt-2 lg:py-3 md:py-3 lg:pl-0 lg:pr-3 md:pl-0 md:pr-0 py-3">
                    <div class="p-5 border-round-md border-1 border-300 border lg:overflow-y-hidden lg:overflow-x-hidden md:overflow-y-hidden md:overflow-x-auto sm:overflow-y-hidden sm:overflow-x-auto overflow-y-hidden overflow-x-auto"
                        style="height: 30rem">
                        <EstadosPorPrestadorChart ref="estadosPorPrestadorChart" />
                    </div>
                </div>
                <div
                    class="col-12 sm:col-12 lg:col-8 md:col-12 p-0 sm:pt-2 lg:py-3 md:py-3 lg:pl-0 md:pl-0 lg:pr-0 md:pr-0 py-3">
                    <div class="p-5 border-round-md border-1 border-300 border" style="height: 30rem">
                        <CidadesComPrestadoresGrid ref="cidadesComPrestadoresGrid" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderPanel from '../components/HeaderPanel.vue';
import { getCurrentCompany, getCurrentCustomers } from '@/services/store';
import CidadesSemPrestadoresGrid from './components/CidadesSemPrestadoresGrid.vue';
import CidadesComPrestadoresGrid from './components/CidadesComPrestadoresGrid.vue';
import EstadosPorPrestadorChart from './components/EstadosPorPrestadorChart.vue';
import QuantidadeTotalPanel from './components/QuantidadeTotalPanel.vue';
import QuantidadePorTipoExameChart from './components/QuantidadePorTipoExameChart.vue';
import TipoDeAtendimentoChart from './components/TipoDeAtendimentoChart.vue';
import QuantidadeAsoRetidoPanel from './components/QuantidadeAsoRetidoPanel.vue';
import DisponibilidadeDeHorarioChart from './components/DisponibilidadeDeHorarioChart.vue';
import UtilizacaoDoPrestadorChart from './components/UtilizacaoDoPrestadorChart.vue';
import MultiSelectPrestador from '../../atualizar-prestador/components/MultiSelectPrestador.vue';

export default {
    components: {
        HeaderPanel,
        CidadesSemPrestadoresGrid,
        CidadesComPrestadoresGrid,
        EstadosPorPrestadorChart,
        QuantidadeTotalPanel,
        QuantidadePorTipoExameChart,
        TipoDeAtendimentoChart,
        QuantidadeAsoRetidoPanel,
        DisponibilidadeDeHorarioChart,
        UtilizacaoDoPrestadorChart,
        MultiSelectPrestador
    },
    data() {
        return {
            customerIds: [],
            prestadorIds: [],
            suppliersSelectedId: [],
            loading: false,

        };
    },
    computed: {
        tenant() {
            return getCurrentCompany();
        },
        currentCustomer() {
            return getCurrentCustomers();
        },
    },
    async mounted() {
        await this.loadAllComponentes();
    },
    watch: {
        currentCustomer(novoValor) {
            if (JSON.stringify(novoValor) !== JSON.stringify(this.customerIds)) {
                this.customerIds = this.currentCustomer;
                setTimeout(() => {
                    this.loadAllComponentes();
                }, 100);
            }
        },
        suppliersSelectedId() {
            this.prestadorIds = this.suppliersSelectedId.map(d => d.id) ?? [];
            setTimeout(() => {
                this.loadAllComponentes();
            }, 100);
        },
    },
    methods: {
        async loadAllComponentes() {
            this.loading = true;
            try {
                await Promise.all([
                    this.$refs.quantidadeTotalPanel.queryData(),
                    this.$refs.quantidadeAsoRetidoPanel.queryData(),
                    this.$refs.cidadesSemPrestadoresGrid.queryData(),
                    this.$refs.disponibilidadeDeHorarioChart.queryData(),
                    this.$refs.quantidadePorTipoExameChart.queryData(),
                    this.$refs.estadosPorPrestadorChart.queryData(),
                    this.$refs.cidadesComPrestadoresGrid.queryData(),
                    this.$refs.tipoDeAtendimentoChart.queryData(),
                    this.$refs.utilizacaoDoPrestadorChart.queryData(),
                ]);
                this.loading = false;
            } catch (error) {
                this.$toast.add({ severity: 'info', summary: 'Ops!', detail: 'Tivemos algum problema ao carregar os gráficos, por favor clique em recarregar!', life: 3000 });
                this.loading = false;
            }
        }
    }
};
</script>
<style scoped>
.card {
    background: #ffffff;
    padding: 1rem;
    box-sizing: border-box;
    margin-bottom: 1rem;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 8%);
    border-radius: 5px;
}

::-webkit-scrollbar-track {
    background-color: #f4f4f436;
    border-radius: 15px;
    margin-left: 5px;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: #F4F4F4;
    margin-left: 5px;
}

::-webkit-scrollbar-thumb {
    background: #ced4da;
    border-radius: 15px;
    margin-left: 5px;
}

.disabled {
    pointer-events: none; 
    opacity: 0.5;
}
</style>
