<template>
    <div class="h-full">
        <HeaderCard :loading="loading" tittle="Quantidade por tipo de exame" @onLoad="load" :comFiltro="false" />
        <div class="w-full h-full flex justify-content-between">
            <div class="w-8 flex justify-content-center align-items-center">
                <Chart type="doughnut" :data="chartData" :options="chartOptions" class="w-20rem" />
            </div>
            <div class="w-5 flex flex-column overflow-auto pt-5 pl-2">
                <div style="display: flex; flex-direction: column; margin: 0 0 20px 0">
                    <span class="text-6xl font-semibold text-800">{{ quantidadeTotalFormatada }}</span>
                    <span class="text-base font-medium text-700">Total de exames</span>
                </div>
                <div class="scrollable-list">
                    <div
                        v-for="(exame, index) in getTipoExames()"
                        :key="index"
                        :style="index === getTipoExames().length - 1 && { margin: '0 0 50px 0' }"
                    >
                        <div class="flex flex-column">
                            <div>
                                <span class="text-4xl font-semibold text-800">{{ this.getQuantidade()[index] }}</span>
                                <span class="text-xl font-semibold text-700 ml-2">{{ this.getPorcentagem()[index] }}%</span>
                            </div>
                            <div class="flex align-items-center">
                                <div :style="{ background: this.getColor()[index], width: '10px', height: '10px' }" />
                                <div class="text-base font-medium text-700 ml-2">{{ exame }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getClientBase } from '@/services/http';
import HeaderCard from '../../components/HeaderCard.vue';
import formatarNumero from '@/utils/FormatadorNumero';
import { uniq } from 'ramda';

export default {
    props: {
        prestadorIds: {
            type: Array
        }
    },
    data() {
        return {
            dados: [],
            periodicidade: 'month',
            dataInicio: null,
            dataFim: null,
            quantidade: 12,
            loading: false,
            quantidadeTotal: 0,
            chartData: null,
            chartOptions: {
                cutout: '50%',
                plugins: {
                    legend: {
                        display: false
                    }
                }
            }
        };
    },
    components: {
        HeaderCard
    },
    computed: {
        stackedData() {
            return {
                labels: this.dados.map(({ tipoExame }) => tipoExame),
                datasets: [
                    {
                        label: 'My Chart',
                        data: this.getQuantidade(),
                        backgroundColor: this.getColor(),
                        hoverBackgroundColor: this.getColor()
                    }
                ]
            };
        },
        stackedOptions() {
            return {
                plugins: {
                    legend: {
                        labels: {
                            usePointStyle: true,
                            color: '#495057'
                        },
                        position: 'right'
                    }
                }
            };
        },
        quantidadeTotalFormatada() {
            return formatarNumero(this.quantidadeTotal);
        }
    },
    methods: {
        setChartData() {
            return {
                labels: this.dados.map(({ descricao }) => descricao),
                datasets: [
                    {
                        data: this.getQuantidade(),
                        backgroundColor: this.getColor(),
                        hoverBackgroundColor: this.getColor()
                    }
                ]
            };
        },
        getTipoExames() {
            const tipoExameUniq = uniq(this.dados.map((d) => d.descricao));
            return tipoExameUniq;
        },
        getQuantidade() {
            const array = [];
            const tipoExameUniq = uniq(this.dados.map((d) => d.descricao));
            for (const tipoExame of tipoExameUniq) {
                const arrayDadosQuantidade = this.dados.filter((d) => d.descricao == tipoExame).map((p) => Number(p.quantidade));
                const soma = arrayDadosQuantidade.reduce((a, b) => a + b, 0);
                array.push(soma);
            }
            return array;
        },
        getPorcentagem() {
            const array = [];
            const tipoExameUniq = uniq(this.dados.map((d) => d.tipoExame));
            for (const tipoExame of tipoExameUniq) {
                const arrayDadosQuantidade = this.dados.filter((d) => d.tipoExame == tipoExame).map((p) => Number(p.porcentagem));
                const soma = arrayDadosQuantidade.reduce((a, b) => a + b, 0);
                array.push(soma.toFixed(2));
            }
            return array;
        },
        getColor() {
            const colorArray = [];
            const tipoExameUniq = uniq(this.dados.map((d) => d.descricao));
            for (const tipoExame of tipoExameUniq) {
                if (tipoExame == 'Acidente') {
                    colorArray.push('#FF4136');
                    continue;
                } else if (tipoExame == 'Admissional') {
                    colorArray.push('#FF851B');
                    continue;
                } else if (tipoExame == 'Consulta') {
                    colorArray.push('#FFDC00');
                    continue;
                } else if (tipoExame == 'Demissional') {
                    colorArray.push('#2ECC40');
                    continue;
                } else if (tipoExame == 'Enfermagem') {
                    colorArray.push('#0074D9');
                    continue;
                } else if (tipoExame == 'Licença Médica') {
                    colorArray.push('#B10DC9');
                    continue;
                } else if (tipoExame == 'Monitoração Pontual') {
                    colorArray.push('#F012BE');
                    continue;
                } else if (tipoExame == 'Mudança de Riscos Ocupacionais') {
                    colorArray.push('#7FDBFF');
                    continue;
                } else if (tipoExame == 'Periódico') {
                    colorArray.push('#3D9970');
                    continue;
                } else if (tipoExame == 'Retorno Consulta') {
                    colorArray.push('#FF5E00');
                    continue;
                } else if (tipoExame == 'Retorno ao Trabalho') {
                    colorArray.push('#FF6F91');
                    continue;
                } else if (tipoExame == 'Terceiros') {
                    colorArray.push('#EABB2E');
                    continue;
                }
            }
            return colorArray;
        },
        async load(params) {
            this.periodicidade = params.periodicidade;
            this.quantidade = params.quantidade;
            this.dataInicio = params.dataInicio;
            this.dataFim = params.dataFim;
            await this.queryData();
        },
        async queryData() {
            this.loading = true;
            try {
                const { data } = await getClientBase().get('/indicadores/prestadores/quantidade-utilizados/por-tipo-exames', {
                    params: {
                        filtros: JSON.stringify({
                            periodicidade: this.periodicidade,
                            quantidade: this.quantidade,
                            prestadorIds: this.prestadorIds?.length ? [this.prestadorIds] : null,
                            dataInicio: this.dataInicio,
                            dataFim: this.dataFim
                        })
                    }
                });
                this.quantidadeTotal = 0;
                data.forEach((exame) => {
                    this.quantidadeTotal += Number(exame.quantidade);
                });
                this.dados = data;
                this.loading = false;
            } catch (error) {
                this.$toast.add({
                    severity: 'info',
                    summary: 'Ops!',
                    detail: 'Tivemos algum problema ao carregar os gráficos, por favor clique em recarregar!',
                    life: 3000
                });
                this.loading = false;
            }
            this.chartData = this.setChartData();
        }
    }
};
</script>

<style scoped>
.scrollable-list {
    overflow-y: auto;
    max-height: 300px;
}
</style>