<template>
    <div>
        <HeaderCard :loading="loading" tittle="Tipo de atendimento" :comFiltro="false" />
        <div class="flex flex-row justify-content-center">
            <Chart type="pie" :data="stackedData" class="w-25rem" :options="chartOptions"/>
        </div>
    </div>
</template>
<script>
import { getClientBase } from '@/services/http';
import HeaderCard from '../../components/HeaderCard.vue';

export default {
    props: {
        prestadorIds: {
            type: Array
        }
    },
    data() {
        return {
            dados: [],
            loading: false,
            chartOptions: {
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: function(tooltipItem) {
                                var total = tooltipItem.formattedValue
                                const label = tooltipItem.label;
                                return `${label}: ${total}%`;
                            }
                        }
                    }
                }
            }
        };
    },
    components: {
        HeaderCard
    },
    computed: {
        stackedData() {
            return {
                labels: ['Ordem de chegada', 'Hora marcada'],
                datasets: [
                    {
                        data: [this.dados.map(p => p.percentualOrdemChegada), this.dados.map(p => p.percentualHoraMarcada)],
                        backgroundColor: ['#4BC0C0', '#FFCD56'],
                    },
                ]
            };
        },
    },
    methods: {
        async queryData() {
            this.loading = true;
            try {
                const { data } = await getClientBase().get('/indicadores/prestadores/quantidade-tipo-atendimento', {
                    params: {
                        prestadorIds: [this.prestadorIds],
                    }
                })
                this.dados = data;
                this.loading = false;
            } catch (error) {
                this.$toast.add({ severity: 'info', summary: 'Ops!', detail: 'Tivemos algum problema ao carregar os gráficos, por favor clique em recarregar!', life: 3000 });
                this.loading = false;
            }
        },
    },
}
</script>